import { sha256_sync } from "./utils/Misc.js";

import { NumericQuery } from "./QueryBuilderComponents/NumericQuery.js";

import { TextQuery } from "./QueryBuilderComponents/TextQuery.js";

import { PickerQuery } from "./QueryBuilderComponents/PickerQuery.js";

import { DateQuery } from "./QueryBuilderComponents/DateQuery.js";
import { DateQueryStart } from "./QueryBuilderComponents/DateQueryStart.js";
import { DateQueryEnd } from "./QueryBuilderComponents/DateQueryEnd.js";

import { CurrentValid } from "./QueryBuilderComponents/Filters/CurrentValid.js";
import { ValidityDateStart } from "./QueryBuilderComponents/Filters/ValidityDateStart.js";
import { ValidityDateEnd } from "./QueryBuilderComponents/Filters/ValidityDateEnd.js";

import { BooleanQuery } from "./QueryBuilderComponents/BooleanQuery.js";

import { MapQuery } from "./QueryBuilderComponents/MapQuery.js";

import { NAMED_KG_RINF } from "../../config/config.js";

export class QueryBuilder {
  constructor(formDefinition, ontologyDefinition) {
    this.components = {
      TextQuery: TextQuery,
      NumericQuery: NumericQuery,
      DateQuery: DateQuery,
      DateQueryStart: DateQueryStart,
      DateQueryEnd: DateQueryEnd,
      PickerQuery: PickerQuery,
      BooleanQuery: BooleanQuery,
      MapQuery: MapQuery,

      // Filters
      CurrentValid: CurrentValid,
      ValidityDateStart: ValidityDateStart,
      ValidityDateEnd: ValidityDateEnd,
    };

    this.formDefinition = formDefinition;
    this.ontologyDefinition = ontologyDefinition;

    //console.log("[QueryBuilder] Loaded:", this.formDefinition);
  }

  // Find element by id

  search_element(id) {
    let element = null;

    for (let definition of this.formDefinition) {
      for (let eid of Object.keys(definition.elements)) {
        if (eid == id) {
          element = definition.elements[id];

          break;
        }
      }

      for (let eid of Object.keys(definition.custom_filters)) {
        if (eid == id) {
          element = definition.custom_filters[id];

          break;
        }
      }
    }

    if (element === null) {
      //console.log("Search element not found: ", id);
    }

    return element;
  }

  validate_search_parameters(search_parameters) {
    let valid = true;

    for (let id of Object.keys(search_parameters.query.value)) {
      let value = search_parameters.query.value[id];

      let element = this.search_element(id);

      if (element === null) {
        valid = false;
      }
    }

    for (let id of Object.keys(search_parameters.query.visible)) {
      let value = search_parameters.query.visible[id];

      let element = this.search_element(id);
      if (element === null) {
        valid = false;
      }
    }

    if (
      this.search_element(
        this.variable_generation("/" + search_parameters.rootObject)
      ) === null
    ) {
      valid = false;
    }

    //console.log(search_parameters, valid);

    return valid;
  }

  clean_search_parameters(search_parameters) {
    for (let id of Object.keys(search_parameters.query.value)) {
      let value = search_parameters.query.value[id];

      let element = this.search_element(id);

      if (element === null) {
        delete search_parameters.query.value[id];
      }
    }

    for (let id of Object.keys(search_parameters.query.visible)) {
      let value = search_parameters.query.visible[id];

      let element = this.search_element(id);

      if (element === null) {
        delete search_parameters.query.visible[id];
      }
    }

    if (
      this.search_element(
        this.variable_generation("/" + search_parameters.rootObject)
      ) === null
    ) {
      // Not recoverable

      search_parameters = {
        query: { visible: {}, value: {} },
        rootObject: null,
      };
    }

    //console.log(search_parameters, valid);

    return search_parameters;
  }

  // Generate SPARQL variables

  variable_generation(element) {
    let variable = sha256_sync(element);

    //console.log("[SHA256]:", element, variable);

    return variable;
  }

  // Generate tree based on query properties and paths

  generate_property_tree(elements, root_object) {
    let tree = {};

    tree[root_object] = {};

    let tree_pos;

    for (let { element, optional } of elements) {
      // Iterate over used elements

      //console.log(element);

      if (
        element.type == "basic" ||
        (element.type == "custom" && element.input.definition.tree)
      ) {
        //console.log("Generate prop tree", element);

        let property_path = element.input.path.concat([element.input.property]);

        //console.log("Element path:", property_path);

        tree_pos = tree[root_object];

        for (let property of property_path) {
          // Iterate over property path of an element

          if (!(property in tree_pos)) {
            tree_pos[property] = { optional: optional };
          } else {
            // Force properties to be mandatory if in the path of a mandatory property

            if (optional === false) {
              tree_pos[property]["optional"] = false;
            }
          }

          tree_pos = tree_pos[property];
        }
      }
    }

    //console.log(elements, tree);

    return tree;
  }

  // Traverse tree to generate triple patterns

  traverse_recursive(branch, root, prev_branch) {
    //console.log("Branch: ", branch, "prev: ", prev_branch, "root", root);

    let sparql_patterns = [];

    let pattern;

    let prev_path = prev_branch + "/" + root;

    //console.log("Branch:", branch);

    /*if(Object.keys(branch).length == 0){

			//console.log("Final element:", root);

		}*/

    for (let key of Object.keys(branch)) {
      if (key !== "optional") {
        let new_branch = branch[key];

        pattern = `?${this.variable_generation(
          prev_path
        )} <${key}> ?${this.variable_generation(prev_path + "/" + key)}.`;

        // Include sprouts

        let sprouts = [];

        let element = this.search_element(
          this.variable_generation(prev_path + "/" + key)
        );

        if (element !== null) {
          let query = new this.components[element.query](
            element,
            null,
            prev_path
          );

          sprouts = query.build_extra_patterns();
        }

        let child_pattern = this.traverse_recursive(new_branch, key, prev_path);

        if (branch["optional"] === true) {
          let concat_child = child_pattern.join("\n");

          pattern =
            "OPTIONAL { \n" +
            pattern +
            "\n" +
            sprouts.join("\n") +
            "\n" +
            concat_child +
            "\n}";

          sparql_patterns.push(pattern);
        } else {
          let concat_child = child_pattern.join("\n");

          pattern =
            pattern + "\n" + sprouts.join("\n") + "\n" + concat_child + "\n";

          sparql_patterns.push(pattern);
        }
      }
    }

    return sparql_patterns;
  }

  traverse_property_tree(tree) {
    let sparql_patterns = [];

    let pattern;

    // Root property is the requested object

    let root = Object.keys(tree)[0];

    pattern = `?${this.variable_generation("/" + root)} a <${root}>.`;

    sparql_patterns.push(pattern);

    // Iterate over root leafs

    //console.log(Object.keys(tree[root]));

    for (let key of Object.keys(tree[root])) {
      if (key !== "optional") {
        //console.log(key, tree[root]["optional"], tree[root][key]["optional"]);

        let branch = tree[root][key];

        //console.log("/"+root+"/"+key);

        pattern = `?${this.variable_generation(
          "/" + root
        )} <${key}> ?${this.variable_generation("/" + root + "/" + key)}.`;

        // Include sprouts

        let sprouts = [];

        let element = this.search_element(
          this.variable_generation("/" + root + "/" + key)
        );

        //console.log(element, key);

        if (element !== null) {
          let query = new this.components[element.query](element, null, root);

          sprouts = query.build_extra_patterns();
        }

        let child_pattern = this.traverse_recursive(branch, key, "/" + root);

        if (tree[root][key]["optional"] === true) {
          //console.log(child_pattern);

          if (child_pattern.length > 0) {
            child_pattern = child_pattern.join("\n");
          } else {
            child_pattern = "";
          }

          pattern =
            "OPTIONAL { \n" +
            pattern +
            "\n" +
            sprouts.join("\n") +
            "\n" +
            child_pattern +
            "\n}";

          sparql_patterns.push(pattern);
        } else {
          if (child_pattern.length > 0) {
            child_pattern = child_pattern.join("\n");
          } else {
            child_pattern = "";
          }

          pattern =
            pattern + "\n" + sprouts.join("\n") + "\n" + child_pattern + "\n";

          sparql_patterns.push(pattern);
        }

        //sparql_patterns = sparql_patterns.concat(child_pattern);
      }
    }

    return sparql_patterns;
  }

  // Generate proper filters for each element

  async get_filter(element, value, root, elements) {
    let filter = [],
      sprouts;

    let query = new this.components[element.query](element, value, root);

    if (element.type === "basic") {
      filter = query.build_filter();

      sprouts = query.build_extra_patterns();

      //console.log(filter);
    }

    // Custom filters can see all elements

    if (element.type === "custom") {
      //console.log("Building filter:", element, value, root);

      filter = await query.build_filter(
        elements,
        this.formDefinition,
        this.ontologyDefinition
      );

      sprouts = query.build_extra_patterns();
    }

    //console.log(filter);

    return { filter: filter, sprouts: sprouts };
  }

  // Generate proper filters for each element (pretasks)

  async prepare_filter(element, value, root, elements) {
    let filter = [],
      sprouts;

    //console.log("Prepare filter", element.query);

    let query = new this.components[element.query](element, value, root);

    if (element.type === "custom") {
      //console.log("Building filter:", element, value, root);

      if (query.prepare_filter !== undefined) {
        await query.prepare_filter(
          elements,
          this.formDefinition,
          this.ontologyDefinition
        );
      }
    }
  }

  // Build query in multiple steps

  async generate_query(query, root_object) {
    let sparql_select_query = [];

    let sparql_construct_query = [];

    //console.group("[QueryBuilder] Generating query");

    //console.log(query, root_object);

    let elements = [];

    let value_elements = [];

    for (let id of Object.keys(query.value)) {
      let value = query.value[id];

      let element = this.search_element(id);

      if (element === null) {
        //console.log("Element ID not found, broken permalink?", id);
      } else {
        elements.push({ element: element, optional: false });
      }

      //value_elements.push(element);
    }

    let visible_elements = [];

    for (let id of Object.keys(query.visible)) {
      let value = query.value[id];

      let element = this.search_element(id);

      if (element === null) {
        //console.log("Element ID not found, broken permalink?", id);
      } else {
        elements.push({ element: element, optional: true });
      }

      //visible_elements.push(element);
    }

    for (let id of Object.keys(query.value)) {
      let value = query.value[id];

      let element = this.search_element(id);

      if (element === null) {
        //console.log("Element ID not found, broken permalink?", element);
      } else {
        await this.prepare_filter(element, value, root_object, elements);
      }
    }

    //console.log("Elements:", elements);
    //console.log("Visible elements:", visible_elements);

    // Build tree using the property and property path of elements

    let tree = this.generate_property_tree(elements, root_object);

    //console.log("Property tree:");
    //console.log(tree);

    // Traverse the property path generating intermediate variables and convert to BGP

    let tree_patterns = this.traverse_property_tree(tree);

    //console.log("Tree patterns:");
    //console.log(tree_patterns);

    // Add extra patterns that can emerge from elements (location -> lat,long and others)

    let sprouts = [];

    // Add filters for elements used as search criteria

    let filters = [];

    for (let id of Object.keys(query.value)) {
      let value = query.value[id];

      let element = this.search_element(id);

      if (element === null) {
        //console.log("Element ID not found, broken permalink?", element);
      } else {
        let { filter, sprout } = await this.get_filter(
          element,
          value,
          root_object,
          elements
        );

        filters = filters.concat(filter);
        sprouts = sprouts.concat(sprout);
      }
    }

    let select_vars = [];

    select_vars.push("?" + this.variable_generation("/" + root_object));

    for (let id of Object.keys(query.visible)) {
      let value = query.value[id];

      let element = this.search_element(id);

      if (element === null) {
        //console.log("Element ID not found, broken permalink?", element);
      } else {
        if (element.type === "basic") {
          let fullpath;

          select_vars.push(`?${id}`);

          // Extra vars from component

          let component_query = new this.components[element.query](
            element,
            null
          );

          let extra_vars = component_query.build_extra_vars();

          select_vars = select_vars.concat(extra_vars);
        } else {
          let component_query = new this.components[element.query](
            element,
            null,
            root_object
          );

          let extra_vars = component_query.build_extra_vars(
            query.visible[id],
            query.value[id]
          );

          select_vars = select_vars.concat(extra_vars);

          //console.log("Extra vars", element, extra_vars);
        }
      }
    }

    //// SELECT

    let select = `SELECT DISTINCT \n\n	${select_vars.join("\n	")}\n`;
    let from = `FROM <http://data.europa.eu/949/graph/uat/rinf>\n`;
    let start_where = `WHERE {\n`;
    let end_where = `\n}`;

    sparql_select_query = sparql_select_query.concat(select);
    sparql_select_query = sparql_select_query.concat(from);
    sparql_select_query = sparql_select_query.concat(start_where);
    sparql_select_query = sparql_select_query.concat(tree_patterns);
    sparql_select_query = sparql_select_query.concat(filters);
    sparql_select_query = sparql_select_query.concat(end_where);

    sparql_select_query = sparql_select_query.join("\n");

    // Ugly fix for canonicalURI

    sparql_select_query = sparql_select_query.replace(
      "?fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc <http://data.europa.eu/949/opStart> ?cf9c2d6d5cd6b614b03885fe9461867485e8cd3e2b9e844bebd26d5f4fa60714.",
      "?fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc <http://data.europa.eu/949/opStart> ?cf9c2d6d5cd6b614b03885fe9461867485e8cd3e2b9e844bebd26d5f4fa60714_canonical.\n" +
        "?cf9c2d6d5cd6b614b03885fe9461867485e8cd3e2b9e844bebd26d5f4fa60714 <http://data.europa.eu/949/canonicalURI> ?cf9c2d6d5cd6b614b03885fe9461867485e8cd3e2b9e844bebd26d5f4fa60714_canonical."
    );

    sparql_select_query = sparql_select_query.replace(
      "?fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc <http://data.europa.eu/949/opEnd> ?4ffdf3c290f5cb01ea6387336bfcb2c54f687ba18e1ed2fbd166ef2b5590aed4.",
      "?fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc <http://data.europa.eu/949/opEnd> ?4ffdf3c290f5cb01ea6387336bfcb2c54f687ba18e1ed2fbd166ef2b5590aed4_canonical.\n" +
        "?4ffdf3c290f5cb01ea6387336bfcb2c54f687ba18e1ed2fbd166ef2b5590aed4 <http://data.europa.eu/949/canonicalURI> ?4ffdf3c290f5cb01ea6387336bfcb2c54f687ba18e1ed2fbd166ef2b5590aed4_canonical."
    );

    //console.log("SELECT query:")
    //console.log(sparql_select_query);

    //// CONSTRUCT

    let start_construct = `CONSTRUCT {`;
    let end_construct = `\n}`;

    let tree_patterns_construct = [];

    for (let pattern of tree_patterns) {
      tree_patterns_construct.push(
        pattern
          .replaceAll("OPTIONAL {", "")
          .replaceAll("}", "")
          .replaceAll(".\n", "---")
          .replaceAll("\n", "")
          .replaceAll("---", ".\n")
      );
    }

    sparql_construct_query = sparql_construct_query.concat(start_construct);
    sparql_construct_query = sparql_construct_query.concat(
      tree_patterns_construct
    );
    sparql_construct_query = sparql_construct_query.concat(end_construct);
    sparql_construct_query = sparql_construct_query.concat(from);
    sparql_construct_query = sparql_construct_query.concat(start_where);
    sparql_construct_query = sparql_construct_query.concat(tree_patterns);
    sparql_construct_query = sparql_construct_query.concat(filters);
    sparql_construct_query = sparql_construct_query.concat(end_where);

    sparql_construct_query = sparql_construct_query.join("\n");

    sparql_construct_query = sparql_construct_query.replace(
      "?fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc <http://data.europa.eu/949/opStart> ?cf9c2d6d5cd6b614b03885fe9461867485e8cd3e2b9e844bebd26d5f4fa60714.",
      "?fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc <http://data.europa.eu/949/opStart> ?cf9c2d6d5cd6b614b03885fe9461867485e8cd3e2b9e844bebd26d5f4fa60714_canonical.\n" +
        "?cf9c2d6d5cd6b614b03885fe9461867485e8cd3e2b9e844bebd26d5f4fa60714 <http://data.europa.eu/949/canonicalURI> ?cf9c2d6d5cd6b614b03885fe9461867485e8cd3e2b9e844bebd26d5f4fa60714_canonical."
    );

    sparql_construct_query = sparql_construct_query.replace(
      "?fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc <http://data.europa.eu/949/opEnd> ?4ffdf3c290f5cb01ea6387336bfcb2c54f687ba18e1ed2fbd166ef2b5590aed4.",
      "?fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc <http://data.europa.eu/949/opEnd> ?4ffdf3c290f5cb01ea6387336bfcb2c54f687ba18e1ed2fbd166ef2b5590aed4_canonical.\n" +
        "?4ffdf3c290f5cb01ea6387336bfcb2c54f687ba18e1ed2fbd166ef2b5590aed4 <http://data.europa.eu/949/canonicalURI> ?4ffdf3c290f5cb01ea6387336bfcb2c54f687ba18e1ed2fbd166ef2b5590aed4_canonical."
    );

    //console.log("CONSTRUCT query:")
    //console.log(sparql_construct_query);

    console.groupEnd();

    return [sparql_select_query, sparql_construct_query];
  }
}
