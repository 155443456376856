// General ENV

export const APP_PATH = '/';


export const BASE_URI = "https://uat.ld4rail.fpfis.tech.ec.europa.eu/"
export const API_URI = BASE_URI;
export const SPARQL_ENDPOINT = 'https://uat.ld4rail.fpfis.tech.ec.europa.eu/api/sparql';
export const SPARQL_ENDPOINT_PUB = 'https://uat.virtuoso.ecdp.tech.ec.europa.eu/sparql';
export const NAV_ENABLED_APPS = 'eyJjb21wb25lbnRzIjogWyJlcmEtc2VhcmNoIiwiZXJhLWNvbXBhdGliaWxpdHktY2hlY2siLCJlcmEtbWFwLWV4cGxvcmVyIiwiZXJhLW5vdGlmaWNhdGlvbnMiLCJlcmEtZGF0YXNldC1tYW5hZ2VyIiwiZXJhLWVuZHBvaW50IiwiZXJhLWRhdGFzdG9yaWVzIiwiZXJhLXZvY2FidWxhcnkiXSwgImV4dHJhcyI6IFsidXNlckxvZ2luIl19';

export const NAMED_KG_RINF = "http://data.europa.eu/949/graph/uat/rinf"
export const NAMED_KG_ERATV = "http://data.europa.eu/949/graph/uat/eratv"
export const NAMED_KG_ERA_VOCABULARY = "http://data.europa.eu/949/graph/uat/ontology"
export const NAMED_KG_ERA_SKOS = "http://data.europa.eu/949/graph/uat/skos"
export const NAMED_KG_ERA_SHACL = "http://data.europa.eu/949/graph/uat/shacl"

// RCC

export const ZOOM = '12';


export const ROUTER_API = API_URI + '/api/route/calculate'
export const SEARCH_HINT_API = API_URI + '/api/search'
export const CHECKS_API = API_URI + '/api/checks/execute/full'



// Search

export const RINF_GRAPH = 'http://data.europa.eu/949/graph/rinf';


export const DESCRIBE_URI = SPARQL_ENDPOINT.replace("/sparql", "/describe/?url=")

export const ERA_ONTOLOGY = API_URI + '/era-vocabulary/ontology.ttl';
export const ERA_TYPES = API_URI + '/era-vocabulary/era-skos';




// Map Explorer


// Notifications

export const NOTIFICATIONS_SEARCH_HINT_API = API_URI + "/api/notifications/search/hints";
export const NOTIFICATIONS_API_URI = API_URI + "/api/notifications/";


// Dataset manager


export const RINF_API_BASEURL = API_URI + "/api"
export const API_ENDPOINT = API_URI + '/api'


// Azure

export const RINF_API_APP_ID_URI = "api://3039f186-99de-4cb5-be4c-1c020a4ad283"
export const CLIENT_ID = "3039f186-99de-4cb5-be4c-1c020a4ad283"
export const TENANT_ID = "25faedbb-f440-4315-83ee-6f7beb5e73f7"
export const REDIRECT_URI = BASE_URI
